<script lang="ts" setup>
import { PropType } from 'vue';
import { TranslationKey } from '~/i18n/TranslationKeys';
import { Localizable } from '~/types/Localizable';
import { FunctionalIds } from '~~/src/constants/funcitonalIds';
import { TextSizes } from '~~/src/constants/textSizes';

const props = defineProps({
    text: { type: String as PropType<TranslationKey | Localizable>, required: true },
    replacements: { type: Object as PropType<string[]>, default: [] },
    show: Boolean,
    class: { type: String, default: 'w-fit' },
    width: { type: String, default: 'max-w-44' },
    centerTooltip: { type: Boolean, default: true },
    stickLeft: Boolean
})
const { text, show, width, class: additionalClasses, centerTooltip, replacements, stickLeft } = toRefs(props)
const mainBody = ref()
const style = ref('')

const popupPosition = async () => {
    await nextTick()

    const associatedRect = mainBody?.value?.getBoundingClientRect()
    if (associatedRect == undefined) {
        return ""
    }
    const { top, height, left, width: mainBodyWidth } = associatedRect

    style.value = `top: ${Math.round(top + height) + 4}px;`
    style.value += stickLeft.value ? `left: ${left}px;` : `left: ${Math.round(left + (mainBodyWidth / 2))}px;`

    if (centerTooltip.value) {
        if (!stickLeft.value) {
            style.value += " transform: translateX(-50%);"
        }
    } else {
        style.value += " transform: translateX(-12px);"
    }
}
// this is necessary as the core element may change position due to additional elements
// (E.G: banners), page resizes, scroll and so on
watch(show, popupPosition)

// on mounted we:
// - await the next tick before rendering, as the tooltip container may not be rendered
// - calculate the popup position in case of pre-displayed popups
const render = ref(false)
onMounted(async () => {
    await nextTick()
    render.value = true

    popupPosition()
})

</script>

<template>
    <ClientOnly>
        <Teleport v-if="render" :to="`#${FunctionalIds.TooltipContainer}`">
            <div v-if="show" class="fixed z-10 max-w-xs p-2 mt-1 rounded-sm bg-dark-blue-700 shadow-high-contrast"
                :class="width" :style="style" style="z-index: 99">
                <CommonText :text="text" :replacements="replacements" class="block text-white whitespace-normal"
                    :text-size="TextSizes.PRODUCTIVE" />
            </div>
        </Teleport>
    </ClientOnly>
    <div ref="mainBody" :class="additionalClasses">
        <slot />
    </div>
</template>